<template>
    <div class="carousel" v-if="carouselDetails.length > 0">
      <div class="carousel-inner" :style="carouselStyles">
        <img
          :src="carouselDetails[0]?.image"
          class="carousel-image"
          alt="cloned last image"
        />
        <!-- Clone last image to be shown before the first image for seamless transition -->
         <div 
          v-for="(image, index) in carouselDetails"
          :key="index"
          class="carousel-image"

         >
         <img
          :src="image?.image"
          alt="carousel image"
          style="min-width: 100%;height: 100%;"
        />
        <div
            class="btn-c"
          >
            <button
              class=""
              v-if="image?.button_text"
              style="width: 100%"
              :style="{ color: image.color,backgroundColor: image.bg}"
              type="button"
              @click="handleRedirect(image.redirect)"
            >
                {{image.button_text}}
            </button>
          </div>

         </div>
       
        
        <!-- Main carousel images -->
      
        <!-- Clone first image to be shown after the last image for seamless transition -->
        <img
          v-if="carouselDetails.length > 0"
          :src="carouselDetails[0]?.image"
          class="carousel-image"
          alt="cloned first image"
        />
       
          
      </div>
      <button @click="prev" class="carousel-control prev">❮</button>
      <button @click="next" class="carousel-control next">❯</button>
    </div>

  </template>
  
  <script>
  export default {
    data() {
      return {
        carouselDetails: [],
        currentIndex: 0,
        transitioning: false,
        autoPlayInterval: null,
        restartAutoPlayTimeout: null, // Timeout to restart autoplay after user interaction
      };
    },
    computed: {
      carouselStyles() {
        const translateX = -(this.currentIndex + 1) * 100;
        return {
          transform: `translateX(${translateX}%)`,
          transition: this.transitioning ? 'transform 0.5s ease-in-out' : 'none', // Smooth transition only when transitioning
        };
      },
    },
    methods: {
      handleRedirect(url) {
      if (url) {
        window.location.href = url;
      } else {
        console.warn("No redirect URL provided.");
      }
    },
      async fetchCarouselData() {
        try {
          const response = await fetch(
            `${process.env.VUE_APP_API_KEY}/api/setting/get-setting/123123?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`
          );
          if (!response.ok) {
            throw new Error("Something went wrong");
          }
          const responseData = await response.json();
          this.carouselDetails = responseData?.data?.carousel_data || [];
        } catch (error) {
          console.error("Error fetching carousel data:", error);
        }
      },
      next() {
        this.stopAutoPlay(); // Stop autoplay when user interacts
        if (!this.transitioning) {
          this.transitioning = true;
          this.currentIndex++;
          if (this.currentIndex === this.carouselDetails.length) {
            // After reaching the clone of the first image, reset to the actual first image without transition
            setTimeout(() => {
              this.transitioning = false;
              this.currentIndex = 0;
            }, 500);
          } else {
            setTimeout(() => {
              this.transitioning = false;
            }, 500); // Transition time
          }
        }
        this.restartAutoPlay(); // Restart autoplay after user interaction
      },
      prev() {
        this.stopAutoPlay(); // Stop autoplay when user interacts
        if (!this.transitioning) {
          this.transitioning = true;
          this.currentIndex--;
          if (this.currentIndex < 0) {
            // After reaching the clone of the last image, reset to the actual last image without transition
            setTimeout(() => {
              this.transitioning = false;
              this.currentIndex = this.carouselDetails.length - 1;
            }, 500);
          } else {
            setTimeout(() => {
              this.transitioning = false;
            }, 500); // Transition time
          }
        }
        this.restartAutoPlay(); // Restart autoplay after user interaction
      },
      startAutoPlay() {
        this.autoPlayInterval = setInterval(() => {
          this.next();
        }, 3000); // Move to the next image every 3 seconds
      },
      stopAutoPlay() {
        if (this.autoPlayInterval) {
          clearInterval(this.autoPlayInterval); // Stop the autoplay interval
          this.autoPlayInterval = null;
        }
        if (this.restartAutoPlayTimeout) {
          clearTimeout(this.restartAutoPlayTimeout); // Clear any pending restart timeout
          this.restartAutoPlayTimeout = null;
        }
      },
      restartAutoPlay() {
        this.restartAutoPlayTimeout = setTimeout(() => {
          this.startAutoPlay(); // Restart autoplay after 3 seconds of inactivity
        }, 3000);
      },
    },
    mounted() {
      this.fetchCarouselData();
      this.startAutoPlay(); // Start autoplay when component is mounted
    },
    beforeDestroy() {
      this.stopAutoPlay(); // Stop autoplay when component is destroyed
    },
  };
  </script>
  
  <style scoped>
  .btn-c{
  position: absolute;
  min-width: 200px;
  max-width: 200px;
  bottom: 20px;
  right: 150px;
  text-align: center;
display: flex; 
z-index: 1000;
justify-content: center;
align-items: center;
padding: 15px;
font-weight: bolder;
border-radius: 10px;

}
.btn-c>button{
  
  font-size: 20px;

}
  .carousel {
    position: relative;
    width: 100%;
    height: 50vh;
    overflow: hidden;
  }
  .carousel-inner {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .carousel-image {
    width: 100%;
    height: 100%;
    position: relative;
    /* object-fit: cover; */
    flex: 0 0 auto;
  }
  .carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.5);
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 20px;
  }
  .prev {
    left: 10px;
  }
  .next {
    right: 10px;
  }
  @media (max-width: 854px) {
    .carousel {
      height: 200px;
    }
    .btn-c{
  position: absolute;
  min-width: 130px;
  max-width: 200px;
  bottom: 10px;
  right: 10px;
  text-align: center;
display: flex; 
z-index: 1000;
justify-content: center;
align-items: center;
padding: 8px;
font-weight: bolder;
border-radius: 6px;

}
.btn-c>button{
  
  font-size: 14px;

}
  }
  </style>
  